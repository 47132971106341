import { OutputConfig } from "./output-config.js"
import { OutputInterface } from './output.js'
import { WebRTCConnector } from "./z-webrtcConnector.js"
import { Lambdas } from './lambdas-api.js'
import { CallApi } from "./call-api.js"
import { StreamParser } from "./StreamParser.js"


import engURL from 'omt:./eng-io.js'
import decoderURL from 'omt:./decoderio.js'
import talentURL from 'omt:./talent-input.js'

import { linkState, syncState, sessionDims } from "./stores.js"

export const Dispatcher = {

    engThread: null,
    decoderThread: null,
    talentThread: null,
    
    talentAudioCtx: null,
    talentStream: null,

    initOutput(){
        
        return new Promise(async (resolve, reject) => {
            try {
                
                let result = await OutputInterface.generateOutput(document.getElementById('displayCanvas'), this.decoderThread)

                this._resizeCanvas()
                window.onresize = e => this._resizeCanvas()

                resolve('ok')
            }
            catch (error) {reject(error)}
        })
        
    },


    async initStreaming(){
        const rtcCreds = await Lambdas.getRTCCreds();
        WebRTCConnector.init(OutputConfig.initInfo.urlResult, rtcCreds, this);
    },

    initCore(){

        this.decoderThread = new Worker(decoderURL, {name: `${OutputConfig.initInfo.urlResult.type}-decoder`, type: 'module'})
        WebRTCConnector.decoderThread = this.decoderThread; //set a ref in the other module
        this.decoderThread.postMessage({init: true, vidConfig: OutputConfig.vidConfig, type: OutputConfig.initInfo.urlResult.type});
        
        this.decoderThread.onmessage = e => {

            if (e.data.videoStream){
                /**
                 * This contains the vStream, so we only need to send it to needsVStream subset of peers. Other peers already got their messages
                 */
                WebRTCConnector.transmitBytes(e.data.videoStream, 1);
            }

            else if (e.data.engLinkState){
                if (this.engThread)
                    this.engThread.postMessage(e.data);
            }

            else if (e.data.dlProgress) {
                
                this._updateSyncState(e.data); //this sets the progress for the local tile
                if (e.data.dlProgress && OutputConfig.initInfo.urlResult.type !== 'eng'){ //this notifies the eng if that is not us
                    CallApi.jitsiConference.sendTextMessage(JSON.stringify({to: 'eng', from: CallApi.jitsiConference.myUserId(), payload: {setRemoteProgress: e.data}}))
                }
                
            }

            else if (e.data.logEvent){
                this.logWorkerEvents(e.data.logEvent);
            }

            //notify eng our streamNeed state
            //tranmit String
            else if ((e.data.needsVStream === true || e.data.needsVStream === false) && OutputConfig.initInfo.urlResult.type !== 'eng'){
                WebRTCConnector.transmitString(e.data);
            }

        }
        
        switch (OutputConfig.initInfo.urlResult.type){
    
            case 'eng':
    
                if (!this.engThread){
                    this.engThread = new Worker(engURL, {name: 'eng-io', type:'module'})
                    this.engThread.postMessage({init: {uri: OutputConfig.initInfo.engInputUri, session: OutputConfig.initInfo.session}}) //uri is passed in
                    this.engThread.onmessage = e => {
                        this.Route(e.data, 'input') 
                    }
                }

                break;
            
            case 'client':

                break;
    
            case 'talent':
                
                if (!this.talentThread){
                    
                    this.talentThread = new Worker(talentURL, {name: 'talent-input', type:'module'})
                    this.talentThread.onmessage = e => {
                        
                         // *** OUTBOUND from talent *** //
                        if (e.data.talentStream && !OutputConfig.talentMuted){
                            WebRTCConnector.transmitTalentStream(e.data.talentStream)
                        }
   
                     }

                }
                
                break;
        }
    
    },

    //Route === 'from'
    async Route (object, route) {

        switch(route){
    
            //eng only
            case 'filehandler':
                
                break;
            
            //eng and talent do this -- it is for transmitting
            case 'input':
               
                // *** OUTBOUND from eng *** //
                if (object.stream && OutputConfig.hasJoinedSession && OutputConfig.sessionClock.isAuth){

                    switch (WebRTCConnector.sendVStream){

                        case true:
                            
                            /**
                             * if we have no TC in this message we send to everyone directly,
                             * if we have TCs, we send to only those that don't need a vStream (since we need to transcode for some people) 
                             */
                            ///Have to update this for vidlist change
                            const sendTo = StreamParser.hasTC(object.stream, OutputConfig.outputConfig) ? 2 : 0;

                            //Send to the correct set or subset of peers
                            WebRTCConnector.transmitBytes(object.stream, sendTo);

                            /**
                             * If we sent to all, that means we just need to render for ourselves,
                             * if we sent only to the !vStream subset, we need to render for ourselves and transcode for the remaining peers
                             */
                            sendTo === 0 ? this.decoderThread.postMessage(object, [object.stream]) : this.decoderThread.postMessage({encodeVStream: object.stream}, [object.stream]);

                            break;

                        case false:

                            /**
                             * no vStreams are needed, so we just send to all and render locally
                             */

                            WebRTCConnector.transmitBytes(object.stream, 0);
                            this.decoderThread.postMessage(object, [object.stream]);
                            break;
                    }

                    
                }

                //outbound transport message -- eng would be the only one to see this
                else if (object.transport){
                    WebRTCConnector.transmitString(object);
                    this.Route(object, 'stream');
                }
                
                //svelte store that holds the link state (connection to plugin)
                else if (object.onLinkChange){
                    linkState.set(object.onLinkChange)
                }

                //results of plugin parse
                else if (object.outputConfig){
                    this.Route({outputConfig: object.outputConfig}, 'stream');
                }

                break;
            
            // *** INBOUND for eng only *** //
            case 'talentStream':

                if (object.talentStream && OutputConfig.hasJoinedSession && OutputConfig.sessionClock.isAuth){
                    this.engThread.postMessage(object, [object.talentStream]); //what if this is sent to a second ws server created by the plugin??
                }

                break;

            // *** INBOUND for all non eng *** //
            case 'stream':
               
                if (object.stream && OutputConfig.hasJoinedSession){
                    this.decoderThread.postMessage(object, [object.stream])      
                }
                
                //inbound message
                else if (object.transport){
                    
                    if (object.transport === 'playing'){
                        OutputConfig.isPlaying = true;
                        WebRTCConnector.cancelPingInterval();
                    }

                    else {
                        OutputConfig.isPlaying = false;
                        WebRTCConnector.startPingInterval();
                    }
                    
                    this.decoderThread.postMessage(object);
                }


                else if (object.outputConfig) {
                    
                    OutputConfig.outputConfig = object.outputConfig;
                    OutputConfig.outputConfig.session['dlcreds'] = OutputConfig.initInfo.urlResult.dlcreds;            
                    
                    this._updateSyncState(this._createSyncObject()); //this is for the ui
                    
                    this.decoderThread.postMessage({outputConfig: OutputConfig.outputConfig});
                    
                    if (OutputConfig.initInfo.urlResult.type === 'eng'){
                        WebRTCConnector.transmitString({outputConfig: OutputConfig.outputConfig}); //transmit updated outputConfig to all connected clients
                    }
                        
                    
                }
                    
                break;

        }
    
    },

    logWorkerEvents(e){

        //console.log('Logging Worker Events')
        switch (e.type){

            case 'error':

                // H.consumeError(new Error(e.msg), {
                //     payload: {
                //         src: e.src
                //     }
                // })

                break;


        }

    },

    syncSessionFile(){ 
        console.error('Is this right??')
        //FileHandler.selectSession(this); 
    },

    async openTalentStream(micId){
        
        if (this.talentThread){
            
            if (this.talentStream){
                console.warn("Stopping Talent Tracks")
                this.talentStream.getTracks().forEach((track) => {
                    if (track.readyState == 'live') 
                        track.stop();
                    
                });
            }

            if (this.talentAudioCtx){
                console.warn("Stopping Talent Context");
                await this.talentAudioCtx.close();
            }


            this.talentStream = await navigator.mediaDevices.getUserMedia({audio: {
                deviceId: micId,
                noiseSuppression: false, 
                autoGainControl: false, 
                echoCancellation: false,
                channelCount: 1,
            }, video: false})
            .catch (e => { return Promise.reject({result: false, reason: e}); } )
            

            this.talentAudioCtx = new AudioContext({
                sampleRate: 48000,
                numberOfChannels: 1,
                latencyHint: "interactive"
            })

            // Currently this is downmixing 2 channels, despite the constraints set above
            const src = this.talentAudioCtx.createMediaStreamSource(this.talentStream);
            const dest = this.talentAudioCtx.createMediaStreamDestination();
            dest.channelCount = 1;
            src.connect(dest);

            const talentTrack = dest.stream.getAudioTracks()[0];
            const audioStream = new MediaStreamTrackProcessor(talentTrack).readable;
            
            //console.log("[Talent Track Created]: ", talentTrack);

            this.talentThread.postMessage({audioStream: audioStream}, [audioStream])
        
        }

        else console.error('No Talent Thread')

    },

    _createSyncObject(){
        //console.log('Creating Sync Object')
        return {sync: {toUpload: OutputConfig.outputConfig.toUpload, toDownload: OutputConfig.outputConfig.toDownload, toCancel: OutputConfig.outputConfig.toCancel}}

    },


    _resizeCanvas(){

        //these are dimmensions needed for proper video sizing
        let canvas = document.getElementById('displayCanvas');
        let { height, width } = canvas.getBoundingClientRect();
        height = Math.round(height);
        width = Math.round(width);
        this.decoderThread.postMessage({resize: {height: height, width: width}})
        //console.log("[Decoder A/R]: ", width / height);

        //these are the dimmensions needed for conference video sizing
        //console.log("Dispatching Dims")
        sessionDims.update(dims => {
            dims.upperThird.height = document.getElementById('upperThird').offsetHeight;
            dims.upperThird.width = document.getElementById('upperThird').offsetWidth;
            return dims
        })
        
        
        
    },  

    _toggleTalentMutes(state){

        const talentTracks = CallApi.jitsiConference.getLocalTracks();
        let audioTrack = null;
        talentTracks.forEach(track => {
            if (track.type === 'audio') audioTrack = track;
        })
        
        if (audioTrack){
            state === 'playing' ? audioTrack.mute() : audioTrack.unmute()
        }
        
    },

    _updateSyncState(syncObj){

        if (syncObj.dlProgress){
            syncState.update(syncState => {
                syncState.up = 0;
                syncState.down = syncObj.dlProgress;
                return syncState;
            });

        }
    },

}


  // _mapToVidList(tc, outputConfig, id){

    //     let parsedTcObjs = []
    
    //     if (!outputConfig || outputConfig.vidlist.length === 0 || id > 0 || tc.length === 0){  
    //         //return [{id: null, frameNum: null, idx: null}]
    //         return []
    //     }
    
    //     else {
    
    //         for (let i=0; i < outputConfig.vidlist.length; i++){
    //             let vidObj = outputConfig.vidlist[i]
                
    //             for (let j=0; j < tc.length; j++){ 
                    
    //                 let frame = tc[j].frameNumber
    //                 let idx = tc[j].idx
    
    //                 if (frame >= vidObj.start && frame < (vidObj.start + vidObj.length)){
    //                     let localFrame = (frame - vidObj.start) + vidObj.offset
    //                     parsedTcObjs.push({id: vidObj.path, frameNum: localFrame, idx: idx})
    //                 }
                    
    //             }
    //         }
    
    //         if (parsedTcObjs.length <= 0){
    //             //return [{id: null, frameNum: null, idx: null}]
    //             return []
    //         }
    
    //         else return parsedTcObjs
    //     }
    
    // },
    
    // _unpackTCStream(tcBlock){
    
    //     let tcObjects = []
    //     for (let i = 0; i < tcBlock.length; i += 2){
    //         tcObjects.push({frameNumber: Number(tcBlock[i]), idx: Number(tcBlock[i + 1])}) //Math.floor(Number(tcBlock[i + 1])/2)
    //     }
    
    //     return tcObjects
    // },

    // toPlugin(audioData){
    //     if (this.engThread)
    //         this.engThread.postMessage({fromConf: audioData}, [audioData])
    // },

